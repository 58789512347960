import { get, post } from '../https.js'

// 添加模板
export const insertConfigModel = (data) => get('/configModel/insertConfigModel', data)

// 新增模板配置
export const insertChildModel = (data) => post('/configModel/insertChildModel', data)

// 查询模板
export const selectByConfigModel = (data) => get('/configModel/selectByConfigModel', data)

// 验证是否可以满足
export const testVerifyAccount = (data) => get('/configModel/testVerifyAccount', data)

// 修改推广链接id
export const updateChildModelWp = (data) => get('/configModel/updateChildModelWp', data)

// 更改状态
export const updateChildModelStatus = (data) => get('/configModel/updateChildModelStatus', data)

// 查询配置
export const selectChildModel = (data) => get('/configModel/selectChildModel', data)

// 查询配置
export const deleteChildModelWp = (data) => get('/configModel/deleteChildModelWp', data)

// 查询筛选条件
export const paramFiltering = (data) => get('/configModel/paramFiltering', data)

// 添加素材模板
export const insertClipLibraryModel = (data) => get('/clipLibraryModel/insertClipLibraryModel', data)

// 查询素材模板
export const selectByClipLibraryModel = (data) => get('/clipLibraryModel/selectByClipLibraryModel', data)

// 查询配置
export const selectClipLibraryChildModel = (data) => get('/clipLibraryModel/selectClipLibraryChildModel', data)

// 新增模板配置
export const insertClipLibraryChildModel = (data) => post('/clipLibraryModel/insertClipLibraryChildModel', data)

// 更改状态
export const updateClipLibraryChildModel = (data) => get('/clipLibraryModel/updateClipLibraryChildModel', data)

// 删除配置信息
export const deleteLibraryChildModel = (data) => get('/clipLibraryModel/deleteLibraryChildModel', data)

// 删除配置信息
export const paramFiltering2 = (data) => get('/clipLibraryModel/paramFiltering', data)

// 获取渠道信息
export const getpartnerPlatform = (data) => get('/configModel/getpartnerPlatform', data)

// 根据投放类型获取书城平台
export const geiDuanJuFenXiao = (data) => get('/configModel/geiDuanJuFenXiao', data)





