/**
 * 数据系统接口
 */
import { get, post } from './http'
// 首页获取请求的参数
export const getRequestList = (data) => get('/home/getRequestList', data)

// 月份获取请求的参数
export const monthgetRequestList = (data) => get('/month/getRequestList', data)

// 首页的总消耗、充值、粉丝数的数据    改动
export const totalData = (data) => post('/home/totalData', data)

// 首页的总回收率
export const TotalRecovery = (data) => get('/home/TotalRecovery', data)

// 首页的总付费成本   改动
export const getPayCost = (data) => post('/home/getPayCost', data)

// 首页下方表格数据   改动
export const tableData = (data) => post('/home/tableData', data)

// 投放获取自己能填写的参数（排除别人已经填写了的）
export const getFillParameter = (data) => get('/home/getFillParameter', data)

// 查询内容是否填写
export const getContext = (data) => get('/home/getContext', data)

// 删除填写内容
export const deleteContext = (data) => post('/home/deleteContext', data)

// 填写内容或修改内容
export const updateContext = (data) => post('/home/updateContext', data)

// 月统计下方表格数据
export const tableDataByMonth = (data) => get('/month/tableDataByMonth', data)


// 月统计的总回收率
export const TotalRecoveryByMonth = (data) => get('/month/TotalRecoveryByMonth', data)


// 月统计的总消耗、充值、粉丝数的数据
export const totalDataByMonth = (data) => get('/month/totalDataByMonth', data)

// 根据请求类型获取集成数据
export const getIntegrationData = (data) => get('/other/getIntegrationData', data)


// 首页的公众号每日回收详情
export const WpRecoveryByDay = (data) => get('/home/WpRecoveryByDay', data)


// 查询所有的测试书
export const selectAllTestBook = (data) => get('/book/selectAllTestBook', data)


// 添加测试书
export const addTestBook = (data) => get('/book/addTestBook', data)

// 把测试书变成都市
export const deleteTestBook = (data) => get('/book/deleteTestBook', data)

// 把测试书变成玄幻
export const updateTestBook = (data) => get('/book/updateTestBook', data)

// 玄幻书
export const selectAllFantasyBook = (data) => get('/book/selectAllFantasyBook', data)

// 根据请求类型获取书名数据  flag: day日 month月
export const getBookName = (data) => get('/other/getBookName', data)

// 书回本数据  flag: day日 month月
export const getBookRecycle = (data) => get('/other/getBookRecycle', data)

// 测试组提成
export const getTestGroup = (data) => get('/bonus/getTestGroup', data)

// 第二版都市提成
export const getSecondVersionOther = (data) => get('/bonus/getSecondVersionOther', data)

// 第三版玄幻提成
export const getThirdlyVersionFantasy = (data) => get('/bonus/getThirdlyVersionFantasy', data)

// 提成导出功能
export const getExcel = (data) => get('bonus/getExcel', data)

// 根据请求类型获取组名
export const getGroupName = (data) => get('/other/getGroupName', data)

// 请求头条系统获取回传数据
export const selectResponseData = (data) => get('other/selectResponseData', data)

// 导出
export const homePageDataExcel = (data) => post('/excel/homePageDataExcel', data)

// 根据订单id查询订单
export const selectOrder = (data) => get('other/selectOrder', data)

// 根据订单id查询订单
export const getPlatformList = (data) => get('other/getPlatformList', data)


