<template>
  <!-- 模版配置 -->
  <div class="paddbox autobox">
    <div>
      <el-card>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="数据配置" :name="first">
            <div class="addBtn flex">
              <el-button type="primary" size="mini" @click="addModelview()"
                >添加数据模版</el-button
              >
            </div>
            <el-divider></el-divider>
            <!-- table部分 -->
            <div>
              <el-table :data="tableData">
                <el-table-column prop="id" label="id" sortable width="80">
                </el-table-column>
                <el-table-column
                  prop="modelName"
                  label="模版名称"
                  sortable
                  width="300"
                >
                </el-table-column>

                <el-table-column
                  prop="nickName"
                  label="姓名"
                  sortable
                  width="180"
                >
                </el-table-column>
                <el-table-column
                  prop="createTime"
                  label="创建时间"
                  sortable
                  width="180"
                >
                </el-table-column>
                <el-table-column
                  prop="status"
                  label="启用状态"
                  sortable
                  width="120"
                >
                  <template #default="scope">
                    <div
                      :style="
                        scope.row.type == 0
                          ? 'color: rgb(245, 108, 108)'
                          : 'color: rgb(103, 194, 58)'
                      "
                    >
                      {{ scope.row.status == 0 ? '禁用' : '启用' }}
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="操作" sortable width="180">
                  <template #default="scope">
                    <div>
                      <el-button
                        type="primary"
                        size="mini"
                        @click="openEditviewFun(scope.row)"
                        >查看配置</el-button
                      >
                      <!-- <el-button
                    type="danger"
                    size="mini"
                    @click="deleteRoleFun(scope.row)"
                    >删除</el-button
                  > -->
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div></el-tab-pane
          >
          <el-tab-pane label="素材配置" :name="second">
            <div class="addBtn flex">
              <el-button type="primary" size="mini" @click="addMaterialView()"
                >添加素材模版</el-button
              >
            </div>
            <el-divider></el-divider>
            <!-- table部分 -->
            <div>
              <el-table :data="tableData2">
                <el-table-column prop="id" label="id" sortable width="80">
                </el-table-column>
                <el-table-column
                  prop="modelName"
                  label="模版名称"
                  sortable
                  width="300"
                >
                </el-table-column>

                <el-table-column
                  prop="nickName"
                  label="姓名"
                  sortable
                  width="180"
                >
                </el-table-column>
                <el-table-column
                  prop="createTime"
                  label="创建时间"
                  sortable
                  width="180"
                >
                </el-table-column>
                <el-table-column
                  prop="status"
                  label="启用状态"
                  sortable
                  width="120"
                >
                  <template #default="scope">
                    <div
                      :style="
                        scope.row.type == 0
                          ? 'color: rgb(245, 108, 108)'
                          : 'color: rgb(103, 194, 58)'
                      "
                    >
                      {{ scope.row.status == 0 ? '禁用' : '启用' }}
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="操作" sortable width="180">
                  <template #default="scope">
                    <div>
                      <el-button
                        type="primary"
                        size="mini"
                        @click="openMaterialEditviewFun(scope.row)"
                        >查看素材配置</el-button
                      >
                      <!-- <el-button
                    type="danger"
                    size="mini"
                    @click="deleteRoleFun(scope.row)"
                    >删除</el-button
                  > -->
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div></el-tab-pane
          >
        </el-tabs>
      </el-card>
    </div>

    <div>
      <!-- 添加模版弹窗 -->
      <el-dialog title="添加模版" v-model="addModelVisibles">
        <div>
          <div>
            <el-form label-width="100px" class="demo-ruleForm">
              <el-form-item label="模版名称：" prop="name">
                <el-input v-model="modelName"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div>
            <div class="add-button-yes flex">
              <div>
                <el-button
                  type="success"
                  plain
                  size="mini"
                  @click="addModelNameFun()"
                  >确定</el-button
                >
              </div>
              <div>
                <el-button
                  type="danger"
                  plain
                  size="mini"
                  @click="addModelNameCancel()"
                  >取消</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </el-dialog>

      <!-- 添加素材模版弹窗 -->
      <el-dialog title="添加素材模版" v-model="addMaterialVisibles">
        <div>
          <div>
            <el-form label-width="100px" class="demo-ruleForm">
              <el-form-item label="模版名称：" prop="name">
                <el-input v-model="MaterialModelName"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div>
            <div class="add-button-yes flex">
              <div>
                <el-button
                  type="success"
                  plain
                  size="mini"
                  @click="addMaterialNameFun()"
                  >确定</el-button
                >
              </div>
              <div>
                <el-button
                  type="danger"
                  plain
                  size="mini"
                  @click="addMaterialNameCancel()"
                  >取消</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </el-dialog>

      <!-- 修改弹窗 -->
      <el-dialog title="修改" v-model="updateWpVisibles">
        <div>
          <div>
            <el-form label-width="100px" class="demo-ruleForm">
              <el-form-item label="公众号：" prop="name">
                <el-input v-model="waitWp"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div>
            <div class="add-button-yes flex">
              <div>
                <el-button
                  type="success"
                  plain
                  size="mini"
                  @click="updateWpFun()"
                  >确定</el-button
                >
              </div>
              <div>
                <el-button
                  type="danger"
                  plain
                  size="mini"
                  @click="changeUpdateWpVisibles()"
                  >取消</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </el-dialog>

      <!-- 删除弹窗 -->
      <el-dialog title="删除" v-model="deleteEditVisibles">
        <div>
          <div>确定要删除数据？</div>
          <div>
            <div class="add-button-yes flex">
              <div>
                <el-button
                  type="success"
                  plain
                  size="mini"
                  @click="deleteChildModelWpFun()"
                  >确定</el-button
                >
              </div>
              <div>
                <el-button
                  type="danger"
                  plain
                  size="mini"
                  @click="changeDeleteEditVisibles()"
                  >取消</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </el-dialog>

      <!-- 删除素材弹窗 -->
      <el-dialog title="删除素材" v-model="deleteMaterialEditVisibles">
        <div>
          <div>确定要删除数据？</div>
          <div>
            <div class="add-button-yes flex">
              <div>
                <el-button
                  type="success"
                  plain
                  size="mini"
                  @click="deleteMaterialChildModelWpFun()"
                  >确定</el-button
                >
              </div>
              <div>
                <el-button
                  type="danger"
                  plain
                  size="mini"
                  @click="changeDeleteMaterialEditVisibles()"
                  >取消</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
      <!-- 查看配置弹窗 -->
      <el-dialog title="查看配置" v-model="openEditVisibles" width="1800px">
        <div class="boxType flex">
          <div>
            <el-button type="primary" plain size="mini" @click="addconfigFun()"
              >添加配置</el-button
            >
          </div>
          <div>
            <el-select
              v-model="paramAccountId"
              filterable
              clearable
              placeholder="账户id"
              @change="selectChildModelFun()"
            >
              <el-option
                v-for="item in paramList.accountIds"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                clearable
              >
              </el-option>
            </el-select>
          </div>
          <div>
            <el-select
              v-model="paramBookName"
              filterable
              clearable
              placeholder="书名"
              @change="selectChildModelFun()"
            >
              <el-option
                v-for="item in paramList.books"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                clearable
              >
              </el-option>
            </el-select>
          </div>
          <div>
            <el-select
              v-model="platformTypes"
              filterable
              clearable
              placeholder="投放平台"
              @change="selectChildModelFun()"
            >
              <el-option
                v-for="item in paramList.platformTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                clearable
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div>
          <div>
            <el-table :data="configTableData">
              <el-table-column prop="id" label="id" sortable width="70">
              </el-table-column>
              <el-table-column prop="userName" label="姓名" sortable width="80">
              </el-table-column>
              <el-table-column
                label="账户id"
                sortable
                width="200"
              >
              <template #default="scope">
                  <div>
                    {{
                     scope.row.accountId2 
                    }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column prop="wp" label="公众号" sortable width="250">
              </el-table-column>

              <el-table-column
                prop="bookName"
                label="书名"
                sortable
                width="200"
              >
              </el-table-column>
              <el-table-column label="小程序类型" sortable width="80">
                <template #default="scope">
                  <div>
                    {{
                      scope.row.videoType == 1
                        ? '微信小程序'
                        : scope.row.videoType == 2
                        ? '抖音小程序'
                        : scope.row.videoType == 3
                        ? 'MiniShorts'
                        : scope.row.videoType == 4
                        ? '快手合集'
                        : 'HoneyReels'
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="platformName"
                label="书城平台"
                sortable
                width="100"
              >
              </el-table-column>
              <el-table-column
                prop="typeOfAmount"
                label="支付模版"
                sortable
                width="80"
              >
                <template #default="scope">
                  <div>
                    {{
                      scope.row.typeOfAmount == 3
                        ? '大额'
                        : scope.row.typeOfAmount == 2
                        ? '中额'
                        : scope.row.typeOfAmount == 1
                        ? '小额'
                        : '其他'
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="adPlatform"
                label="广告平台"
                sortable
                width="80"
              >
              </el-table-column>
              <el-table-column
                prop="partnerPlatform"
                label="渠道"
                sortable
                width="80"
              >
              </el-table-column>

              <el-table-column prop="status" label="状态" sortable width="80">
                <template #default="scope">
                  <div
                    :style="
                      scope.row.status == 0
                        ? 'color: rgb(245, 108, 108)'
                        : 'color: rgb(103, 194, 58)'
                    "
                  >
                    {{ scope.row.status == 0 ? '禁用' : '启用' }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column prop="rebate" label="返点" sortable width="80">
              </el-table-column>
              <el-table-column
                prop="platformType"
                label="投放平台"
                sortable
                width="80"
              >
              </el-table-column>
              <el-table-column prop="type" label="类型" sortable width="80">
                <template #default="scope">
                  <div>
                    {{
                      scope.row.type == 1
                        ? '小说'
                        : scope.row.type == 2
                        ? '测试'
                        : scope.row.type == 3
                        ? '短剧'
                        : scope.row.type == 4
                        ? 'iaa短剧'
                        : scope.row.type == 5
                        ? '奇树'
                        : scope.row.type == 6
                        ? '海外'
                        : '其他'
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" sortable width="360">
                <template #default="scope">
                  <div>
                    <el-button
                      type="primary"
                      size="mini"
                      @click="changeTheStateFun(scope.row)"
                      >更改状态</el-button
                    >
                    <el-button
                      type="warning"
                      size="mini"
                      @click="openUpdateWpFun(scope.row)"
                      >修改</el-button
                    >
                    <el-button
                      type="success"
                      size="mini"
                      @click="copyDataFun(scope.row)"
                      >复用</el-button
                    >
                    <el-button
                      type="danger"
                      size="mini"
                      @click="DeleteDataFun(scope.row)"
                      >删除</el-button
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="pagination" style="margin-top:20px">
            <pagination
              @handleSizeChange="handleSizeChange"
              @handleCurrentChange="handleCurrentChange"
              :total="total"
              :pagesize="pagesize"
              :currentPage="currentPage"
              :pagesizes="pagesizes"
            ></pagination>
          </div>
        </div>
      </el-dialog>

      <!-- 查看素材配置弹窗 -->
      <el-dialog
        title="查看素材配置"
        v-model="openMaterialEditVisibles"
        width="2000px"
      >
        <div class="boxType flex">
          <div>
            <el-button type="primary" plain size="mini" @click="addconfigFun2()"
              >添加素材配置</el-button
            >
          </div>
          <div>
            <el-select
              v-model="paramAccountId2"
              filterable
              clearable
              placeholder="账户id"
              @change="selectClipLibraryChildModelFun()"
            >
              <el-option
                v-for="item in paramList2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div>
          <div>
            <el-table :data="configTableData2">
              <el-table-column prop="id" label="id" sortable width="140">
              </el-table-column>
              <el-table-column
                prop="accountId"
                label="账户id"
                sortable
                width="400"
              >
              </el-table-column>
              <el-table-column prop="status" label="状态" sortable width="140">
                <template #default="scope">
                  <div
                    :style="
                      scope.row.status == 0
                        ? 'color: rgb(245, 108, 108)'
                        : 'color: rgb(103, 194, 58)'
                    "
                  >
                    {{ scope.row.status == 0 ? '禁用' : '启用' }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" sortable width="360">
                <template #default="scope">
                  <div>
                    <el-button
                      type="primary"
                      size="mini"
                      @click="changeMaterialTheStateFun(scope.row)"
                      >更改状态</el-button
                    >
                    <el-button
                      type="danger"
                      size="mini"
                      @click="DeleteMaterialDataFun(scope.row)"
                      >删除</el-button
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="pagination" style="margin-top:20px">
            <pagination
              @handleSizeChange="handleSizeChange2"
              @handleCurrentChange="handleCurrentChange2"
              :total="total2"
              :pagesize="pagesize2"
              :currentPage="currentPage2"
              :pagesizes="pagesizes2"
            ></pagination>
          </div>
        </div>
      </el-dialog>

      <!-- 添加配置弹窗 -->
      <el-dialog title="添加配置" v-model="addEditVisibles" width="800px">
        <!-- <div>
          <template>
            <el-radio-group v-model="form.status">
              <el-radio :label="1">启用</el-radio>
              <el-radio :label="0">禁用</el-radio>
            </el-radio-group>
          </template>
        </div> -->
        <div>
          <el-form label-position="right" label-width="90px" :model="form">
            <el-form-item label="投放平台">
              <el-radio-group v-model="insetPlatformType">
                <el-radio
                  :disabled="waitId.length == 0 ? false : true"
                  label="巨量"
                  >巨量</el-radio
                >
                <el-radio
                  :disabled="waitId.length == 0 ? false : true"
                  label="腾讯广点通"
                  >腾讯广点通</el-radio
                >
                <el-radio
                  :disabled="waitId.length == 0 ? false : true"
                  label="百度"
                  >百度</el-radio
                >
                <el-radio
                  :disabled="waitId.length == 0 ? false : true"
                  label="快手"
                  >快手</el-radio
                >
                <el-radio
                  :disabled="waitId.length == 0 ? false : true"
                  label="海外"
                  >海外</el-radio
                >
              </el-radio-group>
            </el-form-item>
            <!-- <el-form-item label="版本" v-if="insetPlatformType == '腾讯广点通'">
              <el-radio-group v-model="form.version">
                <el-radio
                  :disabled="waitId.length == 0 ? false : true"
                  :label="'v2.0'"
                  >旧版v2.0</el-radio
                >
                <el-radio
                  :disabled="waitId.length == 0 ? false : true"
                  :label="'v3.0'"
                  >新版v3.0</el-radio
                >
              </el-radio-group>
            </el-form-item> -->
            <el-form-item label="投放类型">
              <el-radio-group v-model="form.type" @change="geiDuanJuFenXiaoFun()">
                <el-radio
                  :disabled="waitId.length == 0 ? false : true"
                  :label="3"
                  >短剧</el-radio
                >
                <el-radio
                  :disabled="waitId.length == 0 ? false : true"
                  :label="4"
                  >iaa短剧</el-radio
                >
                <el-radio
                  :disabled="waitId.length == 0 ? false : true"
                  :label="5"
                  >奇树</el-radio
                >
                <el-radio
                  :disabled="waitId.length == 0 ? false : true"
                  :label="6"
                  >Facebook / Tiktok</el-radio
                >
                <el-radio
                  :disabled="waitId.length == 0 ? false : true"
                  :label="7"
                  >快手</el-radio
                >
                <el-radio
                  :disabled="waitId.length == 0 ? false : true"
                  :label="8"
                  >短篇小说</el-radio
                >
              </el-radio-group>
            </el-form-item>

            <el-form-item label="姓名">
              <el-select
                v-model="form.userName"
                filterable
                clearable
                placeholder="请选择投放人"
              >
                <el-option
                  v-for="item in FillParameter.userName"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="小程序类别">
              <el-select
                v-model="form.videoType"
                filterable
                clearable
                placeholder="请选择小程序类别"
              >
                <el-option
                  v-for="item in FillParameter.videoType"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="公众号">
              <el-input clearable v-model="form.wp" :disabled="waitId.length == 0 ? false : true"></el-input>
            </el-form-item>
            <el-form-item label="剧名">
              <el-input clearable v-model="form.bookName"></el-input>
            </el-form-item>
            <el-form-item label="书城平台">
              <el-select
                v-model="form.platformName"
                filterable
                clearable
                placeholder="请选择书城平台"
              >
                <el-option
                  v-for="item in FillParameter.platformName"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="广告平台">
              <el-select
                v-model="form.adPlatform"
                filterable
                clearable
                placeholder="请选择广告平台"
              >
                <el-option
                  v-for="item in FillParameter.adPlatform"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="渠道">
              <el-select
                v-model="form.partnerPlatform"
                filterable
                clearable
                placeholder="请选择渠道"
                @change="changePartnerPlatformFun(item)"
              >
                <el-option
                  v-for="item in PlatformList"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="充值模版">
              <el-select
                v-model="form.typeOfAmount"
                filterable
                clearable
                placeholder="请选择充值模版"
              >
                <el-option
                  v-for="item in FillParameter.typeOfAmount"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="广告账户id">
              <el-input
                clearable
                v-model="form.accountId"
                :disabled="waitId.length == 0 ? false : true"
              ></el-input>
            </el-form-item>

            <el-form-item label="返点">
              <el-input v-model="form.rebate" disabled></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="add-button-yes flex">
          <div>
            <el-button
              type="success"
              plain
              size="mini"
              @click="addconfigDataFun()"
              >确定</el-button
            >
          </div>
          <div>
            <el-button
              type="danger"
              plain
              size="mini"
              @click="addconfigDataCancel()"
              >取消</el-button
            >
          </div>
        </div>
      </el-dialog>

      <!-- 添加素材配置弹窗 -->
      <el-dialog
        title="添加素材配置"
        v-model="addMaterialEditVisibles"
        width="800px"
      >
        <insertView
          @getAccountListFun="getSonAccountListFun"
          @getSelectSonAccountData="getSelectSonAccountData"
          @getCannelFun="getCannelFun"
        ></insertView>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  insertConfigModel,
  insertChildModel,
  selectByConfigModel,
  testVerifyAccount,
  updateChildModelWp,
  updateChildModelStatus,
  selectChildModel,
  deleteChildModelWp,
  paramFiltering,
  insertClipLibraryModel,
  selectByClipLibraryModel,
  paramFiltering2,
  selectClipLibraryChildModel,
  insertClipLibraryChildModel,
  updateClipLibraryChildModel,
  deleteLibraryChildModel,
  getpartnerPlatform,
  geiDuanJuFenXiao,
} from '@/request/new/dataConfig'
import insertView from '@/components/batch/insertView.vue'
import { getFillParameter } from '@/request/dataSystem'
import { ElMessage } from 'element-plus'
import pagination from '@/components/pagination.vue'
import { inject, reactive, toRefs, watch } from 'vue'
import BigNumber from 'bignumber.js';//引入bignumber.js 在表格中显示大数值

export default {
  name: 'dataConfig',
  components: { pagination, insertView },
  setup() {
    const state = reactive({
      //渠道数组
      PlatformList: [],
      // 投放平台（上分下拉框绑定值）
      platformTypes: '',
      // 参数账户id
      paramAccountId: '',
      // 素材参数账户id
      paramAccountId2: '',
      // 参数书名
      paramBookName: '',
      // 请求参数
      paramList: [],
      // 素材请求参数
      paramList2: [],
      // 要删除的id
      dId: '',
      // 要删除素材的id
      dId2: '',
      // 选中的模版id
      cid: '',
      //渠道
      insetPlatformType: '巨量',
      // 条数
      pagesize: 20,
      // 当前页数
      currentPage: 1,
      // 总数
      total: 100,
      pagesizes: [30, 50, 100, 250, 500, 1000, 5000, 10000],
      // 选中的素材模版id
      cid2: '',
      // 条数
      pagesize2: 20,
      // 当前页数
      currentPage2: 1,
      // 总数
      total2: 100,
      pagesizes2: [30, 50, 100, 250, 500, 1000, 5000, 10000],
      // 模版表格数据
      tableData: [],
      // 素材模版表格数据
      tableData2: [],
      // 配置表格数据
      configTableData: [],
      // 配置表格数据
      configTableData2: [],
      // 请求参数
      FillParameter: [],
      // 新添加模版名称
      modelName: '',
      // 新添加素材模版名称
      MaterialModelName: '',
      // 添加模版弹窗控制
      addModelVisibles: false,
      // 添加素材模版弹窗控制
      addMaterialVisibles: false,
      // 查看配置弹窗控制
      openEditVisibles: false,
      // 查看素材配置弹窗控制
      openMaterialEditVisibles: false,
      // 添加配置弹窗控制
      addEditVisibles: false,
      // 添加素材配置弹窗控制
      addMaterialEditVisibles: false,
      // 修改公众号弹窗控制
      updateWpVisibles: false,
      // 删除公众号弹窗控制
      deleteEditVisibles: false,
      // 删除素材弹窗控制
      deleteMaterialEditVisibles: false,
      // 账户id数组
      SonAccountData: [],
      // 填写的配置数据
      form: {
        // 广告账户id
        accountId: '',
        // 公众号
        wp: '',
        // 姓名
        userName: '',

        // 书名
        bookName: '',
        // 广告平台
        adPlatform: '',
        // 渠道
        partnerPlatform: '',
        // 书城平台
        platformName: '',
        // 返点
        rebate: 1.065,
        // 1小说 2测试 3短剧 4 iaa短剧 5奇树 6(Facebook tiktok) 7快手 8短篇小说
        type: 3,
        // 0其他 1小额 2中额 3大额
        typeOfAmount: '',
        // 小程序类别
        videoType: '1',
        // 状态
        status: 1,
        // 标签页
        activeName: 'first',
        //版本  旧版v2.0  新版v3.0
        version: 'v3.0',
      },

      // 要修改的wp
      waitWp: '',
      // 要修改的Id
      waitId: '',
      // 查看配置
      openEditviewFun: (data) => {
        state.cid = data.id
        state.changeOpenEditVisibles()
        state.selectChildModelFun()
        state.paramFilteringFun()
      },
      // 查看素材配置
      openMaterialEditviewFun: (data) => {
        state.cid2 = data.id
        state.changeOpenMaterialEditVisibles()
        state.selectClipLibraryChildModelFun()
        state.paramFiltering2Fun()
      },
      // 查询筛选条件
      paramFilteringFun: () => {
        const datas = {
          mid: state.cid,
        }
        paramFiltering(datas).then((res) => {
          state.paramList = res.data
        })
      },
      // 查询素材筛选条件
      paramFiltering2Fun: () => {
        const datas = {
          cid: state.cid2,
        }
        paramFiltering2(datas).then((res) => {
          state.paramList2 = res.data.accountIds
        })
      },
      // 查看配置数据
      selectChildModelFun: () => {
        const datas = {
          currenPage: state.currentPage,
          size: state.pagesize,
          cid: state.cid,
          bookName: state.paramBookName,
          accountId: state.paramAccountId,
          platformType: state.platformTypes,
        }
        selectChildModel(datas).then((res) => {
          state.currentPage = res.data.currenPage
          state.total = res.data.totalSize
          state.configTableData = res.data.data
        })
      },
      // 查看素材配置数据
      selectClipLibraryChildModelFun: () => {
        const datas = {
          currenPage: state.currentPage2,
          size: state.pagesize2,
          cid: state.cid2,
          accountId: state.paramAccountId2,
        }
        selectClipLibraryChildModel(datas).then((res) => {
          state.currentPage2 = res.data.currenPage
          state.total2 = res.data.totalSize
          state.configTableData2 = res.data.data
        })
      },
      // 更改状态
      changeTheStateFun: (data) => {
        var value = 1
        if (data.status) {
          value = 0
        }

        const datas = {
          id: data.id,
          status: value,
        }
        updateChildModelStatus(datas).then((res) => {
          ElMessage({
            type: 'success',
            message: '状态修改成功',
          })
          state.selectChildModelFun()
        })
      },
      // 改变渠道修改返点
      changePartnerPlatformFun: () => {
        state.PlatformList.forEach((item) => {
          console.log('item', item)
          if (item.name === state.form.partnerPlatform) {
            console.log(item.name)
            state.form.rebate = item.rebate
            return
          }
        })
        // if (state.form.partnerPlatform == '巨网') {
        //   state.form.rebate = 1.065
        // } else {
        //   state.form.rebate = 1.065
        // }
      },

      // 更改素材状态
      changeMaterialTheStateFun: (data) => {
        var value = 1
        if (data.status) {
          value = 0
        }

        const datas = {
          id: data.id,
          status: value,
        }
        updateClipLibraryChildModel(datas).then((res) => {
          ElMessage({
            type: 'success',
            message: '状态修改成功',
          })
          state.selectClipLibraryChildModelFun()
        })
      },
      // 查看配置弹窗变化
      changeOpenEditVisibles: () => {
        state.openEditVisibles = !state.openEditVisibles
      },
      // 查看素材配置弹窗变化
      changeOpenMaterialEditVisibles: () => {
        state.openMaterialEditVisibles = !state.openMaterialEditVisibles
      },
      // 添加模版弹窗变化
      changeAddModelVisibles: () => {
        state.addModelVisibles = !state.addModelVisibles
      },
      // 添加素材模版弹窗变化
      changeAddMaterialVisibles: () => {
        state.addMaterialVisibles = !state.addMaterialVisibles
      },
      // 添加配置弹窗控制
      changeAddEditVisibles: () => {
        state.addEditVisibles = !state.addEditVisibles
      },
      // 添加素材配置弹窗控制
      changeAddMaterialEditVisibles: () => {
        state.addMaterialEditVisibles = !state.addMaterialEditVisibles
      },
      //删除配置弹窗控制
      changeDeleteEditVisibles: () => {
        state.deleteEditVisibles = !state.deleteEditVisibles
      },
      //删除素材配置弹窗控制
      changeDeleteMaterialEditVisibles: () => {
        state.deleteMaterialEditVisibles = !state.deleteMaterialEditVisibles
      },
      // 修改公众号弹窗控制
      changeUpdateWpVisibles: () => {
        state.updateWpVisibles = !state.updateWpVisibles
      },
      // 删除
      DeleteDataFun: (data) => {
        state.dId = data.id
        state.changeDeleteEditVisibles()
      },
      deleteChildModelWpFun: () => {
        const datas = {
          id: state.dId,
        }
        deleteChildModelWp(datas).then((res) => {
          ElMessage({
            type: 'success',
            message: '删除成功',
          })
          state.changeDeleteEditVisibles()
          state.selectChildModelFun()
        })
      },

      // 素材删除
      DeleteMaterialDataFun: (data) => {
        state.dId2 = data.id
        state.changeDeleteMaterialEditVisibles()
      },
      deleteMaterialChildModelWpFun: () => {
        const datas = {
          id: state.dId2,
        }
        deleteLibraryChildModel(datas).then((res) => {
          ElMessage({
            type: 'success',
            message: '删除成功',
          })
          state.changeDeleteMaterialEditVisibles()
          state.selectClipLibraryChildModelFun()
          state.paramFiltering2Fun()
        })
      },
      //根据投放类型获取书城平台
      geiDuanJuFenXiaoFun:()=>{
        const datas ={
          type:state.form.type
        }
        geiDuanJuFenXiao(datas).then((res)=>{
          state.FillParameter.platformName=res.data
        })
      },
      // 添加模版的确定
      addModelNameFun: () => {
        if (state.modelName.length > 0) {
          const datas = {
            modelName: state.modelName,
          }

          insertConfigModel(datas).then((res) => {
            selectByConfigModelFun()
            state.changeAddModelVisibles()
            ElMessage({
              type: 'success',
              message: '添加成功',
            })
            state.modelName = ''
          })
        } else {
          ElMessage({
            type: 'erorr',
            message: '模版名称不能为空',
          })
        }
      },
      // 添加模版的取消
      addModelNameCancel: () => {
        state.changeAddModelVisibles()
      },

      // 接收组件的确定
      getSonAccountListFun: (data) => {
        state.SonAccountData = data
        const datas = {
          cid: state.cid2,
          accountIds: state.SonAccountData,
        }
        insertClipLibraryChildModel(datas).then((res) => {
          ElMessage({
            type: 'success',
            message: '添加成功',
          })
          state.paramFiltering2Fun()
          state.selectClipLibraryChildModelFun()
          state.changeAddMaterialEditVisibles()
        })
      },
      // 接收选择子账户组件的值
      getSelectSonAccountData: (val) => {},
      // 接收组件的取消
      getCannelFun: () => {
        state.changeAddMaterialEditVisibles()
      },
      // 添加素材模版的确定
      addMaterialNameFun: () => {
        if (state.MaterialModelName.length > 0) {
          const datas = {
            modelName: state.MaterialModelName,
          }

          insertClipLibraryModel(datas).then((res) => {
            selectByClipLibraryModelFun()
            state.addMaterialNameCancel()
            ElMessage({
              type: 'success',
              message: '添加成功',
            })
            state.MaterialModelName = ''
          })
        } else {
          ElMessage({
            type: 'erorr',
            message: '模版名称不能为空',
          })
        }
      },

      // 添加素材模版的取消
      addMaterialNameCancel: () => {
        state.changeAddMaterialVisibles()
      },

      // 添加配置的确定
      addconfigDataFun: () => {
        var msg = ''
        if (
          state.form.userName == '' ||
          state.form.accountId == '' ||
          state.form.rebate == '' ||
          state.form.bookName == '' ||
          state.form.adPlatform == '' ||
          state.form.partnerPlatform == '' ||
          state.form.platformName == '' ||
          state.form.type == '' ||
          state.form.videoType == '' ||
          state.form.typeOfAmount == '' ||
          state.form.status == '' ||
          state.form.wp == ''
        ) {
          msg = '有属性为空'
        }

        if (state.form.type != 5 && state.form.platformName == '奇树-短剧') {
          return ElMessage({
            type: 'error',
            message: '奇树短剧只能在奇树页面1',
          })
        }

        if (state.form.type == 5 && state.form.platformName != '奇树-短剧') {
          return ElMessage({
            type: 'error',
            message: '奇树短剧只能在奇树页面2',
          })
        }

        if (msg.length == 0) {
          const datas = {
            cid: state.cid,
            userName: state.form.userName,
            accountId: state.form.accountId,
            rebate: state.form.rebate,
            bookName: state.form.bookName,
            adPlatform: state.form.adPlatform,
            partnerPlatform: state.form.partnerPlatform,
            platformName: state.form.platformName,
            type: state.form.type,
            videoType: state.form.videoType,
            typeOfAmount: state.form.typeOfAmount,
            status: state.form.status,
            wp: state.form.wp,
            platformType: state.insetPlatformType,
            version: state.form.version,
          }
          if (state.waitId != '') {
            // 是修改
            datas.id = state.waitId

            insertChildModel(datas).then((res) => {
              state.changeAddEditVisibles()
              state.selectChildModelFun()
              ElMessage({
                type: 'success',
                message: '修改成功',
              })

              state.form.userName = ''
              state.form.accountId = ''
              state.form.bookName = ''
              state.form.adPlatform = ''
              state.form.partnerPlatform = ''
              state.form.platformName = ''
              state.form.videoType = ''
              state.form.typeOfAmount = ''
              state.form.wp = ''
              state.waitId = ''
              state.insetPlatformType = '巨量'
              state.form.version = 'v3.0'
              state.form.type = 3
            })
          } else {
            // 是新增
            // 先效验
            const datalist = {
              accountId: state.form.accountId,
              wp: state.form.wp,
              platformName: state.form.platformName,
              platformType: state.insetPlatformType,
            }

            testVerifyAccount(datalist).then((res) => {
              if (res.msg == 'success') {
                insertChildModel(datas).then((res) => {
                  state.changeAddEditVisibles()
                  state.selectChildModelFun()
                  ElMessage({
                    type: 'success',
                    message: '添加成功',
                  })

                  state.form.userName = ''
                  state.form.accountId = ''
                  state.form.bookName = ''
                  state.form.adPlatform = ''
                  state.form.partnerPlatform = ''
                  state.form.platformName = ''
                  state.form.videoType = ''
                  state.form.typeOfAmount = ''
                  state.form.wp = ''
                  state.waitId = ''
                  state.insetPlatformType = '巨量'
                  state.form.version = 'v3.0'
                })
              } else {
                ElMessage({
                  type: 'error',
                  message: '效验失败,数据已存在数据库',
                })
              }
            })
          }
        } else {
          console.log('提升=', state.form)
          ElMessage({
            type: 'error',
            message: '请把数据填写完整',
          })
        }
      },
      // 添加配置的取消
      addconfigDataCancel: () => {
        state.changeAddEditVisibles()
      },

      // 打开添加模版弹窗
      addModelview: () => {
        state.changeAddModelVisibles()
      },
      // 打开添加素材模版弹窗
      addMaterialView: () => {
        state.changeAddMaterialVisibles()
      },
      // 打开修改弹窗
      openUpdateWpFun: (data) => {
        state.addconfigFun()
        // 获取规则id
        state.waitId = data.id
        state.form.wp = data.wp
        state.form.accountId = data.accountId2
        state.form.userName = data.userName
        state.form.rebate = data.rebate
        state.form.bookName = data.bookName
        state.form.adPlatform = data.adPlatform
        state.form.partnerPlatform = data.partnerPlatform
        state.form.platformName = data.platformName
        state.form.type = data.type
        state.form.typeOfAmount = data.typeOfAmount
        state.form.videoType = data.videoType
        state.form.status = 1
        state.insetPlatformType = data.platformType
        state.form.version = data.version
      },
      // 修改
      updateWpFun: () => {
        if (state.waitWp.length == 0) {
          return ElMessage({
            type: 'error',
            message: '公众号不能为空',
          })
        }
        const datas = {
          id: state.waitId,
          wp: state.waitWp,
        }
        updateChildModelWp(datas).then((res) => {
          ElMessage({
            type: 'success',
            message: '公众号修改成功',
          })
          state.changeUpdateWpVisibles()
          state.selectChildModelFun()
        })
      },
      // 复用
      copyDataFun: (data) => {
        state.waitId = ''
        state.addconfigFun()
        state.form.accountId = ''
        state.form.wp = ''
        state.form.userName = data.userName
        state.form.rebate = data.rebate
        state.form.bookName = data.bookName
        state.form.adPlatform = data.adPlatform
        state.form.partnerPlatform = data.partnerPlatform
        state.form.platformName = ''
        state.form.type = data.type
        state.form.typeOfAmount = data.typeOfAmount
        state.form.videoType = data.videoType
        state.insetPlatformType = data.platformType
        state.form.version = data.version
        state.form.status = 1
      },
      // 分页开始
      // 页数 发生改变
      handleCurrentChange: (e) => {
        state.currentPage = e
        state.selectChildModelFun()
      },
      // 个数 发生改变
      handleSizeChange: (e) => {
        state.pagesize = e
        state.selectChildModelFun()
      },
      // 页数 发生改变
      handleCurrentChange2: (e) => {
        state.currentPage2 = e
        state.selectClipLibraryChildModelFun()
      },
      // 个数 发生改变
      handleSizeChange2: (e) => {
        state.pagesize2 = e
        state.selectClipLibraryChildModelFun()
      },
      // 获取请求参数
      getFillParameterFun: () => {
        const s = new Date().getTime() - 3600 * 1000 * 24
        const date = new Date(s)
        // 年
        const year = date.getFullYear()
        // 月
        const month =
          date.getMonth() + 1 < 10
            ? '0' + (date.getMonth() + 1)
            : date.getMonth() + 1
        // 日
        const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()

        const datas = {
          starTime: year + '-' + month + '-' + day,
          endTime: year + '-' + month + '-' + day,
          type: 3, //前端写死短剧
        }
        console.log(year + '-' + month + '-' + day)
        getFillParameter(datas).then((res) => {
          state.FillParameter = res.data
          state.geiDuanJuFenXiaoFun()
        })
      },
      //
      getpartnerPlatformFun: () => {
        getpartnerPlatform().then((res) => {
          state.PlatformList = res.data
        })
      },
      // 添加配置
      addconfigFun: () => {
        state.waitId = ''
        state.form.userName = ''
        state.form.accountId = ''
        state.form.bookName = ''
        state.form.adPlatform = ''
        state.form.partnerPlatform = ''
        state.form.platformName = ''
        state.form.videoType = ''
        state.form.typeOfAmount = ''
        state.insetPlatformType = '巨量'
        state.form.wp = ''
        state.form.version = 'v3.0'

        state.getFillParameterFun()
        state.getpartnerPlatformFun()
        state.changeAddEditVisibles()
      },
      // 添加素材配置
      addconfigFun2: () => {
        //请求参数为空
        state.changeAddMaterialEditVisibles()
      },
      //accountid变成字符串显示
      formatBigNumber(value){
        return new BigNumber(value).toString();
      }
    })

    // 查询模板
    const selectByConfigModelFun = () => {
      selectByConfigModel().then((res) => {
        state.tableData = res.data
      })
    }

    selectByConfigModelFun()

    // 查询素材模板
    const selectByClipLibraryModelFun = () => {
      selectByClipLibraryModel().then((res) => {
        state.tableData2 = res.data
      })
    }

    selectByClipLibraryModelFun()




    return {
      // 结构赋值，暴露出去就不用 state.xx
      ...toRefs(state),
      selectByConfigModelFun,
      selectByClipLibraryModelFun,
    }
  },
}
</script>

<style lang="scss" scoped>
.add {
  div {
    padding-right: 50px;
  }
}

.add-button-yes {
  justify-content: flex-end;
  > div {
    margin-left: 40px;
  }
}

.belong-text {
  width: 800px;
  color: cornflowerblue;
  font-size: 20px;
}

.boxType {
  > div {
    margin-right: 200px;
  }
}
</style>
