<template>
  <div class="">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="pagesizes"
      :page-size="pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: 'pagination',
  props: {
    // 多少条一页
    pagesizes: {
      type: Array,
      default: [10, 20, 50, 100],
    },
    // 当前多少条数据一页
    pagesize: Number,
    // 当前页数
    currentPage: Number,
    // 总数
    total: Number,
  },
  setup() {
    return {}
  },
  methods: {
    // 页数发生改变
    handleCurrentChange(e) {
      this.$emit('handleCurrentChange', e)
    },
    // 个数 发生改变
    handleSizeChange(e) {
      this.$emit('handleSizeChange', e)
    },
  },
  computed: {},
}
</script>
<style lang="scss" scoped></style>
